import { spacings } from '@apimmo/front/style/theme'
import { remCalc } from '@apimmo/front/utils/selectors'
import styled from '@emotion/styled'
import { Logo } from '~/components/Logo'
import { colors } from '~/style/theme'

export const SideBarWrapper = styled.div`
  grid-area: sidebar;
  height: 100%;
  width: 100%;
  background: ${process.env.SIDEBAR_COLOR || colors.secondary};
`

export const StickyMenu = styled.div`
  position: sticky;
  top: 0;
`

export const StyledLogo = styled(Logo)`
  padding: ${spacings.medium} ${spacings.large} ${remCalc(68)} ${spacings.large};
`

export const Nav = styled.nav`
  display: grid;
`
